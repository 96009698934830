import $ from 'jquery';
import select2 from 'select2';

select2();

$(document).ready(() => {
  $('.locations-input').select2({
    tags: true,
    language: {
      noResults() { return 'Locations konnte nicht gefunden werden'; },
    },
  });
});
