import $ from 'jquery';
import select2 from 'select2'

select2()

function clearDamageTypes(target) {
  $(target.parentElement)
    .siblings()
    .find('.damage-types-input')
    .val(null)
    .trigger('change');
}

function clearDamageSeverity(target) {
  $(target.parentElement)
    .siblings()
    .find('.damage-severity-input')
    .val('undamaged');
}

function updateDamageTypeField() {
  const damagedPieces = parseInt(
    $('#outbound_shipment_pieces_damaged').length
      ? $('#outbound_shipment_pieces_damaged').val()
      : $('#inbound_shipment_pieces_damaged').val(),
    10,
  );
  const damageTypeField = $('#damage_types_select');
  const select2Container = damageTypeField.next('.select2-container');
  const selectedDamageTypes = damageTypeField.val();

  damageTypeField.prop('required', damagedPieces > 0);
  select2Container.toggleClass('required-field', damagedPieces > 0);

  if (selectedDamageTypes && selectedDamageTypes.length > 0) {
    select2Container.removeClass('required-field');
  }
}

$(document).ready(() => {
  $('.damage-types-input').select2();

  $('.pieces-damaged-input').on('change keyup input', (event) => {
    const piecesDamagedInputField = event.target;
    const damaged = $(piecesDamagedInputField).val() !== '0';
    const $damageCategoryInputs = $(piecesDamagedInputField)
      .parent()
      .siblings('.damage-categories');
    $damageCategoryInputs.toggle(damaged);
    if (!damaged) {
      clearDamageTypes(piecesDamagedInputField);
      clearDamageSeverity(piecesDamagedInputField);
    }
    updateDamageTypeField();
  });

  updateDamageTypeField();

  $('#outbound_shipment_pieces_damaged, #inbound_shipment_pieces_damaged').on(
    'input',
    () => {
      updateDamageTypeField();
    },
  );

  $('.damage-types-input').on('change', () => {
    updateDamageTypeField();
  });
});
